import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function SectionCategories() {

    const {t} = useTranslation("leadership");

    return (
        <>
            <div className="widget widget_services ">
                <h4 className="widget-title">{t("contacts")}</h4>
                <ul className="bgs-white ">
                    <li className="font-primary">
                        <div className="margin-bottom10">
                            <NavLink className="margin-sx-icon" to="/contact-us">
                                <span className="font-primary"> valentin.drogorano@scanteq.com</span>
                            </NavLink>
                            <div className="sx-icon-bx-3">
                                <div className="sx-media">
                                    <i className="flaticon-email-1" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="font-primary">
                        <NavLink className="margin-sx-icon" to="/contact-us">
                            <span className="font-primary"> +39 327 5875963</span>
                        </NavLink>
                        <div className="sx-icon-bx-3">
                            <div className="sx-media">
                                <i className="flaticon-incoming-call" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            
        </>
    )
}
export default SectionCategories;