import { HiBadgeCheck } from "react-icons/hi";

const BenefitCard = ({ title, dataList }) => {
    return (
        <>
            <div className="benefit-card">
                <div className="benefit-card-title">
                    {title}
                </div>
                <div className="benefit-card-content">
                <ul className="benefit-card-content-list">
                    {dataList.map((data,index) => (
                          <li> {<div><HiBadgeCheck/></div>} {data}</li>
                    ))}
                </ul>
                </div>
            </div>
        </>
    );
};

export default BenefitCard;