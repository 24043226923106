import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlinePause } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineStepBackward } from "react-icons/ai";
import { AiOutlineMonitor } from "react-icons/ai";



const VideoPlayer = ({ handlePlay, handlePause, handleBackward, handleLeft, handleRight, handleMonitor, isPlaying }) => {
    return (
        <>
            <div className="video-player-div">{/**/}
                {isPlaying ?
                    <AiFillCaretRight onClick={handlePlay}></AiFillCaretRight> :
                    <AiOutlinePause onClick={handlePause}></AiOutlinePause>
                }
                <AiOutlineStepBackward onClick={handleBackward}></AiOutlineStepBackward>
                <AiOutlineArrowLeft onClick={handleLeft}></AiOutlineArrowLeft>
                <AiOutlineArrowRight onClick={handleRight}></AiOutlineArrowRight>
                <AiOutlineMonitor onClick={handleMonitor}></AiOutlineMonitor>
            </div>
        </>
    );
};

export default VideoPlayer;
