const Title = ({ title, description, descriptionList, addClass }) => {
    return (
        <>
            <div className={`card-viewer-upper-part ${addClass}`}>

                {title !== null && <div className="for-mobile-view">
                    <div className="card-viewer-upper-part-title">
                        {title}
                    </div>

                </div>}

                {description !== null && description && <div className="for-mobile-view">
                    <div className="card-viewer-upper-part-desc ">
                        {description}
                    </div>
                </div>}

                {descriptionList !== null && descriptionList && Array.isArray(descriptionList) && <div className="for-mobile-view">
                    {descriptionList.map((desc, index) => (
                        <div key={index} className="card-viewer-upper-part-desc ">
                            {desc}
                        </div>
                    ))}
                </div>}

            </div>
        </>
    );
};

export default Title;