import React from 'react';
import styled from 'styled-components';
import ItodoImage from '../../elements/itodo-img';

const DemoMaspWrapper = styled.div`
  background-color: black;
  height:100vh;
  display:flex;
  justify-content: center;
  align-items: center;
`;

const DemoMasp = () => {
    return (
        <>
            <DemoMaspWrapper>

                <div className='width100 perflex'>
                    <div className='width60'>

                        <div>
                            <div className='masp-demo-upperbar'>
                                <label>Masp App 2.0.0</label>
                                <button>X</button>
                            </div>
                            <div className='masp-demo-mainframe'>
                                <div className='masp-demo-mainframe-primo'>
                                    <div className='masp-demo-mainframe-primo-modal'>
                                        <ItodoImage src="/images/masp/masp.png"></ItodoImage>
                                        <div className='masp-demo-mainframe-primo-modal-text'>
                                            ATTESA NUOVO CICLO
                                        </div>
                                    </div>
                                </div>
                                <div className='masp-demo-mainframe-secondo'>
                                    <div className='masp-demo-upperblack'>
                                        Macchina Pronta
                                        <br></br>
                                        <br></br>
                                        Atteso nuovo ciclo
                                    </div>
                                    <div className='masp-demo-upperblack2'></div>
                                    <div className='masp-demo-upperblack3'></div>
                                    <div className='masp-demo-secondo-btns'>
                                        <button>Login</button>
                                        <button>Posaggio</button>
                                        <button>Supporto</button>
                                        <button>Setup</button>
                                        <button>Istruzioni</button>
                                        <label>Codice:</label>
                                        <textarea></textarea>
                                        <button>ODP</button>
                                        <button>Start</button>
                                        <button>About</button>
                                        <button>OK</button>
                                        <button>Cancel</button>
                                        <button>Diagnostica</button>
                                        <button>Stampa</button>
                                        <button>Uscita</button>
                                        <button>Arresta</button>

                                    </div>
                                </div>
                            </div>
                            <div className='masp-demo-bottombar'>
                                <label>User: </label>
                                <label>Robot: Non usato</label>
                                <label>OP Wire: Connesso</label>
                                <label>OP WiFi: Connesso</label>
                                <label>US: Non usato</label>
                                <label>Server: Non usato</label>
                                <label>DB: Non usato</label>
                                <label>Log: OK</label>

                            </div>
                        </div>

                    </div>

                </div>
            </DemoMaspWrapper>
        </>
    );
};

export default DemoMasp;
