
import { SectionWhyChooseUs2Inner } from "./section-why-choose-us2";
import PercheSceglierciNew from "./perche_sceglierci_new";

function SectionWhyChooseUs4() {
    return (
        <>
            <PercheSceglierciNew></PercheSceglierciNew>

        </>
    )
}

export default SectionWhyChooseUs4;