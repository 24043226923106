import { NavLink, useNavigate, useLocation } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { getAuth, signOut } from 'firebase/auth';

function Header21() {

    const [email, setEmail] = useState(null);
    const auth = getAuth();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [isComponentMounted, setIsComponentMounted] = useState(true);
  
    useEffect(() => {
        // Verifica se c'è un utente autenticato
        if (auth.currentUser) {
          // Ottieni l'indirizzo email dell'utente autenticato
          const userEmail = auth.currentUser.email;
          setEmail(userEmail);
        }
    
        // Cleanup della componente quando viene smontata
        return () => {
          setIsComponentMounted(false);
        };
      }, [auth]);
    
      function toggleNavClass() {
        setIsActive(!isActive);
      }


      const handleLogout = async () => {
        try {
          await signOut(auth);
    
          // Chiama la funzione destroy solo se il componente è montato
          if (isComponentMounted) {
            // Chiamare la funzione destroy o la logica necessaria per chiudere il menu
          }
    
          navigate("/login"); // Reindirizza l'utente alla pagina di login dopo il logout
        } catch (error) {
          console.error("Errore durante il logout:", error.message);
        }
      };

      const location = useLocation();

      const getCurrentPageName = () => {
        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];
        
        // Mappa il nome della pagina in base al percorso
        const pageNameMap = {
          home: "Home",
          tracciabilita: "Traceability Hub",
          controlhome: "Control Hub",
          maintenancehome: "Maintenance Hub",
          ticket: "Ticket",
          ticketadmin: "Ticket",
          "stato-postazioni": "Panoramica",
          "all-risultati": "Risultati",
          "tracciabilita-avvitature": "Avvitature",
          "tracciabilita-barcode": "Barcode",
          "tracciabilita-termoforo": "Termoforo",
          "tracciabilita-coppia": "Coppia",
          "errori-postazioni" : "Errori postazioni",
          "log-postazioni": "Log postazioni",
          "analytics" : "Analisi statica",
          "analisi-dinamica" : "Analisi dinamica",
          "panoramicapostazionipick" : "Panoramica",
          "gestionipostazionipick" : "Gestione",
          "pickdemo" : "Pick Demo"
        };
    
        // Restituisci il nome della pagina mappato o il nome del segmento iniziale maiuscolo
        return pageNameMap[lastSegment] || lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
      };

    return (
        <>
            <header className={"header-style2 site-header mobile-sider-drawer-menu full-navbar " + (isActive ? "active" : "")}>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div id="headertracc" className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    {/*<NavLink to="/">
                                        <ItodoImage id = 'logoscanteq' src="images/logo.png" alt="#" />
    </NavLink>*/}
                                    <h2 className="arear">© Area Riservata</h2>
                                    <h2 className="arear">{getCurrentPageName()}</h2>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            <div className="extra-nav">
                
                                <div className="extra-cell">
                                    <div className="sx-topbar-left">
                                        <ul className="e-p-bx">
                                            <li>{email}</li>
                                            <li className="active">
                                        <NavLink id="logout" onClick={handleLogout}>Logout</NavLink>
                                    </li>
                                            {/*<li><a href="tel:+39 327 5875963"></a></li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    <li className="active">
                                        <NavLink to="/home">Home</NavLink>
                                    </li>
                                    <li className="active">
                                        <NavLink to="/controlhome">Control Hub</NavLink>
                                    </li>
                                    <li className="active">
                                        <NavLink to="/tracciabilita">Traceability Hub</NavLink>
                                    </li>
                                    <li className="active">
                                        <NavLink to="/maintenancehome">Maintenance Hub</NavLink>
                                    </li>
                                    {/*<li className="active">
                                        <NavLink to="/mes">Mes</NavLink>
                                    </li>
                                    <li className="active">
                                        <NavLink to="/picktoimage">Pick To Image</NavLink>
</li>*/}
                                    <li className="active">
            {email === 'admin@auth.com' ? (
              <NavLink to="/ticketadmin">Ticket</NavLink>
            ) : (
              <NavLink to="/ticket">Ticket</NavLink>
            )}
          </li>
          <li className="activesss">
                                        <NavLink id="esci"  to="/">Esci</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header21;