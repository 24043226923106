import Title from "../../elements/title";
import SectionCaseStudy2 from "./section-case-study2";
import { useTranslation } from 'react-i18next';
import HomeSection from "../../elements/homeSection";

const ImmaginiAcquisite = () => {

    const { t } = useTranslation("imagina");
    const getIncrementalDescriptions = (variabile) => {
        let descriptions = [];
        let i = 1;
        let desc;

        while ((desc = t(`${variabile}${i}`)) && desc !== `${variabile}${i}`) {
            descriptions.push(desc);
            i++;
        }

        return descriptions;
    };

    const descriptions = getIncrementalDescriptions("immagini.desc_");

    return (
        <>
            {/*<div className="section-full wow fadeInLeft" data-wow-duration="1500ms">
                <Title title={t("immagini.header")}></Title>
    </div>*/}
      
            <HomeSection
                title={t("immagini.header")}
                imgSrcList={t('immagini.dataImg', { returnObjects: true })}
                descriptionList={descriptions}
                putBackground={true}
                buttonText={t("immagini.button")}
                buttonLink="/contact-us"
            >
            </HomeSection>
            {/*<SectionCaseStudy2></SectionCaseStudy2>*/}

    
        </>
    );
};

export default ImmaginiAcquisite;