import { useTranslation } from "react-i18next";
import ItodoImage from "../../elements/itodo-img";
import { LuClipboardCheck } from "react-icons/lu";
import { LuDatabase } from "react-icons/lu";
import { LuPencilRuler } from "react-icons/lu";
import { NavLink, Link } from "react-router-dom";

const MaspNewBanner = () => {

    const { t } = useTranslation("masp_caratteristiche");


    return (
        <>
            {true &&
                <div className="masp-banner-div">
                    <div className="masp-banner-div-div">
                        <div className="masp-banner-title">
                            {t("banner.header")}
                        </div>
                        <div className="masp-banner-desc">
                            {t("banner.desc")}
                        </div>
                        <Link to={"#new-header-div"}><button id="button-third">{t("banner.btn")}</button></Link>
                        <div className="masp-new-banner-choices">
                        <Link to={"#new-header-div"}><div className="masp-new-banner-choices-div">
                                <div className="masp-new-banner-choices-icon section-full wow fadeInUp" data-wow-delay="0ms" data-wow-duration="500ms">
                                    <LuClipboardCheck />
                                </div>
                                {t("banner.characteristics")}
                            </div></Link>
                            <NavLink to="/masp-operazioni"><div className="masp-new-banner-choices-div">
                                <div className="masp-new-banner-choices-icon section-full wow fadeInUp" data-wow-delay="200ms" data-wow-duration="500ms">
                                    <LuPencilRuler />
                                </div>
                                {t("banner.operations")}
                            </div>
                            </NavLink>
                            <NavLink to="/masp-tracciabilita"><div className="masp-new-banner-choices-div">
                                <div className="masp-new-banner-choices-icon section-full wow fadeInUp" data-wow-delay="400ms" data-wow-duration="500ms">
                                    <LuDatabase />
                                </div>
                                {t("banner.traceability")}
                            </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="masp-new-banner-div-img section-full wow fadeInUp" data-wow-duration="1000ms">
                        <ItodoImage src="/images/masp/intro.png"></ItodoImage>
                    </div>
                </div>
            }
        </>
    );
};

export default MaspNewBanner;