import { useTranslation } from "react-i18next";
import VideoBanner from "../../elements/videoBanner";
const ChiSiamoBanner = () => {

    const {t} = useTranslation("chi_siamo");
    return (
        <>
            <VideoBanner
                Title={t("intro.header")}
                Desc={t("intro.desc")}
                Button={t("intro.button")}
                imgSrc="/images/about/sfondo.png"
                videoSrc="/assets/images/about/video3.mp4"
                isVideo={true}
                href="#aboutpane"
                ButtonThird={true}
                backgroundColor="rgba(2, 40, 255, 0.3)"
            />
        </>
    );
};

export default ChiSiamoBanner;