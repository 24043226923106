import Banner from "../../elements/common/banner";
import SectionAboutCompany4 from "./section-about-company4";
import SectionOurInfo2 from "./section-our-info2";
import SectionVideo2 from "../../elements/common/section-video2";
import SectionWhyChooseUs2 from "../perche_sceglierci/section-why-choose-us2";
import Clienti_Component from "../clienti/clienti_component";
import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import ServicesHome from "../servizi/servizi_home/servicesHome";
import VideoBanner from "../../elements/videoBanner";
import { useTranslation } from "react-i18next";

function MissionVisionPage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    const {t} = useTranslation("vision");

    return (
        <>
            {/*<Banner _data={bannerData.mission} />*/}
            <VideoBanner
                Title={t("intro.header")}
                Desc={t("intro.desc")}
                Button={t("intro.button")}
                imgSrc="/images/about/sfondo.png"
                videoSrc="/assets/images/about/mission/video1.mp4"
                isVideo={true}
                href="#introcontainer"
                ButtonThird={true}
                backgroundColor="rgba(2, 40, 255, 0.3)"
            />
            <SectionAboutCompany4 />
            {/*<SectionOurInfo2/ >*/}
            <ServicesHome></ServicesHome>
            <SectionVideo2 />
            <SectionWhyChooseUs2></SectionWhyChooseUs2>

        </>
    )
}

export default MissionVisionPage;