import SectionVideo2 from "../../elements/common/section-video2";
import SectionWhyChooseUs4 from "../perche_sceglierci/section-why-choose-us4";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionAboutCompany5 from "./section-about-company5";
import ServicesHome from "../servizi/servizi_home/servicesHome";
import ChiSiamoBanner from "./chi_siamo_banner";
import { useLocation } from 'react-router-dom';
import CarsBanner from "./cars_banner";

function Chi_Siamo_Page() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            <ChiSiamoBanner></ChiSiamoBanner>
            {/*<Banner _data={bannerData.about} />*/}
            <SectionAboutCompany5></SectionAboutCompany5>
            <CarsBanner></CarsBanner>
            <ServicesHome></ServicesHome>
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
        </>
    )
}

export default Chi_Siamo_Page;