import Banner from "../../elements/common/bannerautoufficio";
import { bannerData } from "../../../globals/banner";
import ImaginaIntro from "./imaginaintro";
import ImaginaCaratteristiche from "./imaginacaratteristiche";
import ScanQuest from "./scanquest";
import ImmaginiAcquisite from "./immaginiacquisite";
import VideoBanner from "../../elements/videoBanner";
import { useTranslation } from "react-i18next";

const ImaginaHome = () => {

    const { t } = useTranslation("imagina");

    return (
        <>

            {/*<Banner _data={bannerData.autoufficio} /> */}
            <VideoBanner
                Title={t("intro.header")}
                Desc={t("intro.desc")}
                Button={t("intro.button")}
                imgSrc=""
                videoSrc="/assets/images/case-study/video.mp4"
                isVideo={true}
                href="#inizioref"
                ButtonThird={true}
                backgroundColor="rgba(2, 40, 255, 0.3)"
            />
            <div className="">
                <ImaginaIntro></ImaginaIntro>
            </div>
            <div className="panesupervisorcomm bggrey section-full p-t110 mobile-page-padding width100 perflex">
                <div className="container">
                    <ImaginaCaratteristiche></ImaginaCaratteristiche>
                </div>
            </div>
            <div className="panesupervisorcomm bgwhite section-full p-t110 mobile-page-padding width100 perflex">
                <div className="container">
                    <ScanQuest></ScanQuest>
                </div>
            </div>
            <div className="">
                <ImmaginiAcquisite></ImmaginiAcquisite>
                {/*<SectionBlogSingle></SectionBlogSingle>*/}
            </div>
        </>
    );
};

export default ImaginaHome;