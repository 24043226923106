import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ItodoImage from './itodo-img';

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'url(assets/images/jobs/carousel/left.png) no-repeat center center',
                backgroundSize: 'contain',
                width: '50px',
                height: '50px',
                marginLeft: '20px',
                position: 'absolute',
                zIndex: 1,
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
            }}
            onClick={onClick}
        />
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'url(assets/images/jobs/carousel/right.png) no-repeat center center',
                backgroundSize: 'contain',
                width: '50px',
                height: '50px',
                marginRight: '20px',
                position: 'absolute',
                zIndex: 1,
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
            }}
            onClick={onClick}
        />
    );
};
const BannerViewerCard = ({ exitCallback, title, folderPath, ListImg }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        if (slider1.current && slider2.current) {
            slider1.current.slickGoTo(currentSlide);
            slider2.current.slickGoTo(currentSlide);
            console.log(currentSlide);
        }
    }, [currentSlide]);
    
    const settingsMain = {
        infinite: ListImg.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        /*asNavFor: slider2.current || undefined,*/
        draggable: false,
        swipe: false,
        pauseOnHover: false,
        afterChange: (index) => {
            setCurrentSlide(index);
        },
        
    };
    
    const settingsThumbs = {
        slidesToShow: Math.min(10, ListImg.length),
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: false,
        centerMode: false,
        initialSlide: 0,
        infinite: false,
        draggable: false,
        swipe: false,
        pauseOnHover: false,
    };

    return (
        <>
            <div className="modal-overlay">
                <div className="job-viewer-card">
                    <div className="job-viewer-card-title">
                        {title}
                    </div>
                    <div className='width100 perflex'>
                        <div className="job-viewer-card-desc">
                        </div>
                    </div>
                    <div className='carousel-job-viewer-wrapper'>
                        <Slider {...settingsMain} ref={slider1} className='carousel-job-viewer'>
                            {ListImg.map((image, index) => (
                                <div key={index}>
                                    <div className='job-viewer-card-title-imgdiv'>
                                        <ItodoImage className='job-viewer-card-title-img' src={image.src} alt={`Image ${index + 1}`} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className='current-slides'>{currentSlide + 1} / {ListImg.length}</div>

                        <div className='perflex width100'>
                            <div className='perflex width80'>
                                <Slider {...settingsThumbs} ref={slider2} className='carousel-job-viewer-thumbs'>
                                    {ListImg.map((image, index) => (
                                        <div className='carousel-job-viewer-thumbs-inner' key={index} onClick={() => setCurrentSlide(index)}>
                                            <ItodoImage className='job-viewer-card-thumb-img' src={image.src} alt={`Thumb ${index + 1}`} />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <button className="job-viewer-card-closer" onClick={exitCallback}>X</button>
                </div>
            </div>
        </>
    );
}

export default BannerViewerCard;
