import { useState, useEffect } from "react";
import ItodoImage from "./itodo-img";
import JobViewerCard from "./jobViewerCard";
import { head } from "@vercel/blob";

const JobCard = ({ directory, enable, title, description, imgSrc, buttonText }) => {
    const [openView, setOpenView] = useState(false);
    const [hasImages, setHasImages] = useState(false);

    /*useEffect(() => {
        const checkImages = async () => {
            try {
                const token = 'vercel_blob_rw_bxvO8aQg0W9Y3egE_k9UpHpYH7oeDu4QmS0KKPgSqUevdGQ'; // Sostituisci con il tuo token
                console.log("Token:", token);
                const response = await head(`/carousel/${title}`, {
                    token: token,  // Passa il token qui
                });
                setHasImages(response.ok);
            } catch (error) {
                console.error("Blob error:", error);
                setHasImages(false);
            }
        };
    
        checkImages();
    }, [title]);*/

    const openJobViewerCard = () => {
        setOpenView(!openView);
    };

    return (
        <>
            <div className="card-viewer-lower-part-card">
                <div className="primary-circle">
                    <ItodoImage className="immaginebianca" src={imgSrc}></ItodoImage>
                </div>
                {enable && <button onClick={openJobViewerCard} id="button-third-small">{buttonText}</button>}
                <div className="card-viewer-lower-part-card-title ">
                    {title}
                </div>
                <div className="card-viewer-lower-part-card-desc">
                    {description}
                </div>
            </div>

            {openView && <JobViewerCard folderName={directory} title={title} desc={description} exitCallback={openJobViewerCard}></JobViewerCard>}
        </>
    );
}

export default JobCard;
