
import SectionCategories from "./section-categories";


function SectionSidebar() {
    return (
        <>
            <div className="side-bar sx-bg-white">
                <SectionCategories />
            </div>
        </>
    )
}
export default SectionSidebar;