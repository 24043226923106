export const bannerData = {
    scheda_imagina: {
        background: "images/banner/imagina.jpg",
        title_it: "Scheda imagina",
        crumb_it: "Scheda imagina",
        title_ro: "Placa imagina",
        title_en: "Imagina sheet",
        crumb_ro: "Placa imagina",
        crumb_en: "Imagina sheet",
        title_fr: "Fiche imagina",
        crumb_fr: "Fiche imagina"
    },
    scheda_controllo_digitale: {
        background: "images/banner/hardware.png",
        title_it: "Scheda di controllo digitale",
        crumb_it: "Scheda di controllo digitale",
        title_ro: "Placa de control digital",
        title_en: "Digital control sheet",
        crumb_ro: "Placa de control digital",
        crumb_en: "Digital control sheet",
        title_fr: "Fiche de contrôle numérique",
        crumb_fr: "Fiche de contrôle numérique"
    },
    schede_automazione: {
        background: "images/banner/hardware.png",
        title_it: "Schede per automazione",
        crumb_it: "Schede per automazione",
        title_ro: "Fișe pentru automatizare",
        title_en: "Automation electronic boards",
        crumb_ro: "Fișe pentru automatizare",
        crumb_en: "Automation electronic board",
        title_fr: "Cartes électroniques d'automatisation",
        crumb_fr: "Cartes électroniques d'automatisation"
    },
    unita_di_controllo: {
        background: "images/banner/hardware.png",
        title_it: "Unità di controllo",
        crumb_it: "Unità di controllo",
        title_ro: "Unitate de control",
        title_en: "Control unit",
        crumb_ro: "Unitate de control",
        crumb_en: "Control unit",
        title_fr: "Unité de contrôle",
        crumb_fr: "Unité de contrôle"
    },
    mes: {
        background: "images/banner/mes.jpg",
        title_it: "MASP MES",
        crumb_it: "MASP MES",
        title_ro: "MASP MES",
        title_en: "MASP MES",
        crumb_ro: "MASP MES",
        crumb_en: "MASP MES",
        title_fr: "MASP MES",
        crumb_fr: "MASP MES"
    },
    picking: {
        background: "images/banner/picking.jpg",
        title_it: "Picking",
        crumb_it: "Picking",
        title_ro: "Picking",
        title_en: "Picking",
        crumb_ro: "Picking",
        crumb_en: "Picking",
        title_fr: "Picking",
        crumb_fr: "Picking"
    },
    tracciabilita: {
        background: "images/banner/tracciahome.jpg",
        title_it: "MASP Supervisor",
        crumb_it: "MASP Supervisor",
        title_ro: "Supraveghetor MASP",
        title_en: "Supervisor MASP",
        crumb_ro: "Supraveghetor MASP",
        crumb_en: "Supervisor MASP",
        title_fr: "Superviseur MASP",
        crumb_fr: "Superviseur MASP"
    },
    ticket: {
        background: "images/banner/leadership.jpg",
        title_it: "Apri Ticket",
        crumb_it: "Ticket",
        title_ro: "Deschide Tichet",
        title_en: "Open Ticket",
        crumb_ro: "Tichet",
        crumb_en: "Ticket",
        title_fr: "Ouvrir un ticket",
        crumb_fr: "Ticket"
    },
    leadership: {
        background: "images/banner/leadership.jpg",
        title_it: "Leadership",
        crumb_it: "Leadership",
        title_ro: "Lider",
        title_en: "Leadership",
        crumb_ro: "Lider",
        crumb_en: "Leadership",
        title_fr: "Leadership",
        crumb_fr: "Leadership"
    },
    autoufficio: {
        background: "images/banner/autoufficio.jpg",
        title_it: "Imagina",
        crumb_it: "Imagina",
        title_ro: "Imagina",
        title_en: "Imagina",
        crumb_ro: "Imagina",
        crumb_en: "Imagina",
        title_fr: "Imagina",
        crumb_fr: "Imagina"
    },
    masp_caratteristiche: {
        background: "images/banner/automazionedue.jpg",
        title_it: "MASP Caratteristiche",
        crumb_it: "MASP Caratteristiche",

        title_ro: "MASP Caracteristici",
        crumb_ro: "MASP Caracteristici",

        title_en: "MASP Features",
        crumb_en: "MASP Features",

        title_fr: "MASP Caractéristiques",
        crumb_fr: "MASP Caractéristiques" 
    },
    masp_operazioni: {
       background: "images/banner/automazionedue.jpg",
        title_it: "MASP Operazioni",
        crumb_it: "MASP Operazioni",

        title_ro: "MASP Operațiuni",
        crumb_ro: "MASP Operațiuni",

        title_en: "MASP Operations",
        crumb_en: "MASP Operations",
        
        title_fr: "MASP Opérations",
        crumb_fr: "MASP Opérations"
    },
    masp_tracciabilita: {
        background: "images/banner/automazionedue.jpg",
        title_it: "MASP Tracciabilità",
        crumb_it: "MASP Tracciabilità",

        title_ro: "MASP Trasabiliate",
        crumb_ro: "MASP Trasabiliate",

        title_en: "MASP Traceability",
        crumb_en: "MASP Traceability",
        
        title_fr: "MASP Traçabilité",
        crumb_fr: "MASP Traçabilité"
    },
    automazione: {
        background: "images/banner/automazione.jpg",
        title_it: "Automazione",
        crumb_it: "Automazione",
        title_ro: "Automatizare",
        title_en: "Automation",
        crumb_ro: "Automatizare",
        crumb_en: "Automation",
        title_fr: "Automatisation",
        crumb_fr: "Automatisation"
    },
    hardware: {
        background: "images/banner/hardware.png",
        title_it: "Soluzioni Hardware",
        crumb_it: "Soluzioni Hardware",
        title_ro: "Soluții Hardware",
        title_en: "Hardware Solutions",
        crumb_ro: "Soluții Hardware",
        crumb_en: "Hardware Solutions",
        title_fr: "Solutions matérielles",
        crumb_fr: "Solutions matérielles"
    },
    schede: {
        background: "images/banner/software.jpg",
        title_it: "Schede Elettroniche",
        crumb_it: "Schede Elettroniche",
        title_ro: "Fișe Electronice",
        title_en: "Electronic Sheets",
        crumb_ro: "Fișe Electronice",
        crumb_en: "Electronic Sheets",
        title_fr: "Fiches électroniques",
        crumb_fr: "Fiches électroniques"
    },
    about: {
        background: "images/banner/bnr-7.png",
        title_it: "Chi siamo",
        crumb_it: "Chi siamo",
        title_ro: "Despre noi",
        title_en: "About us",
        crumb_ro: "Despre noi",
        crumb_en: "About us",
        title_fr: "À propos de nous",
        crumb_fr: "À propos de nous"
    },
    icons: {
        background: "images/banner/bnr-4.jpg",
        title_it: "Icons",
        crumb_it: "icon-font",
        title_ro: "Icone",
        title_en: "Icons",
        crumb_ro: "icon-font",
        crumb_en: "icon-font",
        title_fr: "Icônes",
        crumb_fr: "icon-font"
    },
    mission: {
        background: "images/banner/mission.jpg",
        title_it: "Vision",
        crumb_it: "Vision",
        title_ro: "Viziune",
        title_en: "Vision",
        crumb_ro: "Viziune",
        crumb_en: "Vision",
        title_fr: "Vision",
        crumb_fr: "Vision"
    },
    services: {
        background: "images/banner/competenze.png",
        title_it: "Servizi",
        crumb_it: "Servizi",
        title_ro: "Servicii",
        title_en: "Services",
        crumb_ro: "Servicii",
        crumb_en: "Services",
        title_fr: "Services",
        crumb_fr: "Services"
    },
    contact: {
        background: "images/banner/bnr-6.jpg",
        title_it: "Contattaci",
        crumb_it: "Contattaci",
        title_ro: "Contactează-ne",
        title_en: "Contact us",
        crumb_ro: "Contactează-ne",
        crumb_en: "Contact us",
        title_fr: "Contactez-nous",
        crumb_fr: "Contactez-nous"
    }
}
