import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from "./loader";
import axios from 'axios';

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'url(assets/images/jobs/carousel/left.png) no-repeat center center',
                backgroundSize: 'contain',
                width: '50px',
                height: '50px',
                marginLeft: '20px',
                position: 'absolute',
                zIndex: 1,
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
            }}
            onClick={onClick}
        />
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'url(assets/images/jobs/carousel/right.png) no-repeat center center',
                backgroundSize: 'contain',
                width: '50px',
                height: '50px',
                marginRight: '20px',
                position: 'absolute',
                zIndex: 1,
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
            }}
            onClick={onClick}
        />
    );
};

const JobViewerCard = ({ exitCallback, title, desc, folderName, folderPath }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        const loadImages = async () => {
            if (folderName) {
                try {
                    const formattedTitle = folderName.toLowerCase();
                    const folderPath = `assets/images/jobs/carousel/${formattedTitle}/`;

                    const imageList = [];
                    let index = 1;

                    while (true) {
                        const imageUrl = `${folderPath}image${index}.png`;
                        const img = new Image();
                        img.src = imageUrl;

                        const imageLoaded = await new Promise((resolve) => {
                            img.onload = () => resolve(true);
                            img.onerror = () => resolve(false);
                        });

                        if (imageLoaded) {
                            imageList.push(imageUrl);
                            index++;
                        } else {
                            break;
                        }
                    }

                    setImages(imageList);
                } catch (error) {
                    console.error("Errore nel caricamento delle immagini:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadImages();
    }, [folderName]);

    useEffect(() => {
        if (slider1.current && slider2.current) {
            slider1.current.slickGoTo(currentSlide);
            slider2.current.slickGoTo(currentSlide);
            console.log(currentSlide);
        }
    }, [currentSlide]);

    const settingsMain = {
        infinite: images.length >= 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        /*asNavFor: slider2.current || undefined,*/
        draggable: false,
        swipe: false,
        pauseOnHover: false,
        afterChange: (index) => {
            setCurrentSlide(index);
        },
    };

    const settingsThumbs = {
        slidesToShow: Math.min(10, images.length),
        slidesToScroll: 1,
        /*asNavFor: slider1.current || undefined,*/
        focusOnSelect: true,
        arrows: false,
        centerMode: true,
        centerPadding: false,
        initialSlide: 0,
        infinite: false,
        draggable: false,
        swipe: false,
        pauseOnHover: false
    };

    return (
        <>
            <div className="modal-overlay">
                <div className="job-viewer-card">
                    <div className="job-viewer-card-title">
                        {title}
                    </div>
                    <div className='width100 perflex'>
                        <div className="job-viewer-card-desc">
                            {desc}
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        images.length > 1 ?
                            <div className='carousel-job-viewer-wrapper'>
                                <Slider {...settingsMain} ref={slider1} className='carousel-job-viewer'>
                                    {images.map((image, index) => (
                                        <div key={index}>
                                            <div className='job-viewer-card-title-imgdiv'>
                                                <img className='job-viewer-card-title-img' src={image} alt={`Image ${index + 1}`} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className='current-slides'>{currentSlide + 1} / {images.length}</div>

                                <div className='perflex width100'>
                                    <div className='perflex width80'>
                                        <Slider {...settingsThumbs} ref={slider2} className='carousel-job-viewer-thumbs'>
                                            {images.map((image, index) => (
                                                <div className='carousel-job-viewer-thumbs-inner' key={index} onClick={() => setCurrentSlide(index)}>
                                                    <img className='job-viewer-card-thumb-img' src={image} alt={`Thumb ${index + 1}`} />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='width100 perflex padding-bottom50px'>
                                <div className="width50 perflex">
                                    <img className='job-viewer-card-thumb-img' src={images[0]} />
                                </div>
                            </div>
                    )}
                    <button className="job-viewer-card-closer" onClick={exitCallback}>X</button>
                </div>
            </div>
        </>
    );
}

export default JobViewerCard;
