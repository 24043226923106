import React from "react";
import Banner from "../../elements/common/banner";
import { bannerData } from "../../../globals/banner";
import IntroUnita from "./introunita";
import Controllo_I_Nostri_Prodotti from "./controllo_inostriprodotti";
import { useTranslation } from 'react-i18next';
import Title from "../../elements/title";


const UnitaControlloHome = () => {


    const { t } = useTranslation("unita_di_controllo");


    return (
        <>
            <Banner _data={bannerData.unita_di_controllo} />

            <div className="panesupervisorcomm bgwhite section-full p-t110 mobile-page-padding">
                <div className="section-full wow fadeInDown" data-wow-duration="1500ms">
                    <Title title={t("intro.header")}></Title>
                </div>

                <div className="container">
                    <IntroUnita></IntroUnita>
                </div>
            </div>
            <div className="panesupervisorcomm bggrey section-full p-t110 mobile-page-padding ">
                <div className="section-full wow fadeInDown" data-wow-duration="1500ms">
                    <Title title={t("i_nostri_prodotti.header")}></Title>
                </div>
                <div className="container">
                    <Controllo_I_Nostri_Prodotti></Controllo_I_Nostri_Prodotti>
                </div>
            </div>

        </>
    );
};

export default UnitaControlloHome;
