import React, { useState } from 'react';
import { BiSolidPlusCircle } from 'react-icons/bi';

const FaqCard = ({ title, desc, wowDelay, wowDuration }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-card-div-upper section-full wow fadeInUp" data-wow-delay={wowDelay} data-wow-duration={wowDuration}>
            <div className="faq-card-div">
                <div className="faq-card-div-desc">
                    {title}
                </div>
                <div className={`faq-card-div-icon ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
                    <BiSolidPlusCircle />
                </div>
            </div>

            <div className={`faq-card-opened ${isOpen ? 'open' : ''}`}>
                {desc}
            </div>
        </div>
    );
};

export default FaqCard;