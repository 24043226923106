import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItodoImage from "./itodo-img";
import VideoPlayer from "./VideoPlayer";
import { AiFillPauseCircle } from "react-icons/ai";
import BannerViewerCard from "./bannerViewerCard";

const BannerCardSlider = ({ List, containerHeight, flip, imgLogo, title }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [shrink, setShrink] = useState(false);
    const [isPause, setIsPause] = useState(false);
    const sliderRef = useRef(null);
    const [viewer, setViewer] = useState(false);

    let slider = true;

    useEffect(() => {
        if (List.length > 1) {
            const interval = setInterval(() => {
                setShrink(true);
                setTimeout(() => {
                    setTimeout(() => {
                        setShrink(false);
                    }, List[currentIndex].fixedSeconds);
                    setCurrentIndex(prevIndex => (prevIndex + 1) % List.length);
                }, List[currentIndex].transitionSeconds);
            }, List[currentIndex].totalSeconds);
            return () => clearInterval(interval);
        }
    }, [List.length, currentIndex, List]);

    let src, width, widthLower, color1, color2, colorBackGround, transitionSecondsCss;

    try {
        ({ src, width, widthLower, color1, color2, colorBackGround, transitionSecondsCss } = List[currentIndex]);
    } catch (error) {
        console.error('BANNERCARD si è verificato un errore:', error);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        draggable: false,
        swipe: false,
        pauseOnHover: false,
        beforeChange: () => setShrink(true),
        afterChange: (current) => {
            setCurrentIndex(current);
            setShrink(false);
        }
    };

    const videoPlayerHandlePlay = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
            setIsPause(false);
        }
    };

    const videoPlayerHandlePause = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause();
            setIsPause(true);
        }
    };
    const videoPlayerHandleBackward = () => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    };

    const videoPlayerHandleLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const videoPlayerHandleRight = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const videoPlayerHandleMonitor = () => {
        setViewer(true);
    };

    const onExitCallback = () => {
        setViewer(!viewer);
    }

    return (
        <>
            {!slider || List.length === 1 && (
                <div className="banner-card-background" style={{
                    backgroundColor: colorBackGround,
                    transition: 'background-color 1s',
                    flex: 1,
                    height: containerHeight + 100,
                    borderTopRightRadius: flip ? '0' : '50px',
                    borderBottomRightRadius: flip ? '0' : '50px',
                    borderTopLeftRadius: flip ? '50px' : '0',
                    borderBottomLeftRadius: flip ? '50px' : '0',
                }}>
                    <div className="banner-card-firstcircle" style={{ backgroundColor: color1, transition: 'background-color 1s' }}></div>
                    <div className="banner-card-secondcircle" style={{ backgroundColor: color2, transition: 'background-color 1s' }}></div>
                    {imgLogo && <ItodoImage className={flip ? "banner-card-logoimg-left" : "banner-card-logoimg-right"} src={imgLogo}></ItodoImage>}
                    <ItodoImage
                        className="banner-card-img"
                        src={src}
                        style={{
                            height: List.length > 1 && shrink ? widthLower : width,
                            transition: `height ${transitionSecondsCss}`,
                        }}
                    />
                </div>
            )}
            {List.length > 1 && slider && (
                <div className="banner-card-background-slider" style={{
                    backgroundColor: colorBackGround,
                    transition: 'background-color 1s',
                    flex: 1,
                    height: containerHeight + 100,
                    borderTopRightRadius: flip ? '0' : '50px',
                    borderBottomRightRadius: flip ? '0' : '50px',
                    borderTopLeftRadius: flip ? '50px' : '0',
                    borderBottomLeftRadius: flip ? '50px' : '0',
                }}>
                    {/*<div className="banner-card-firstcircle" style={{ backgroundColor: color1, transition: 'background-color 1s' }}></div>
                    <div className="banner-card-secondcircle" style={{ backgroundColor: color2, transition: 'background-color 1s' }}></div>*/}
                    {imgLogo && <ItodoImage className={flip ? "banner-card-logoimg-left" : "banner-card-logoimg-right"} src={imgLogo}></ItodoImage>}
                    <Slider ref={sliderRef} {...settings}>
                        {List.map((item, index) => (
                            <div key={index} className="banner-card-img-sliderwrapper-wrapper" style={{ height: containerHeight + 100, }}>
                                <div className="banner-card-img-sliderwrapper" style={{ height: containerHeight + 100, }}>
                                    <ItodoImage
                                        className="banner-card-img-slider "
                                        src={item.src}

                                    />
                                </div>
                            </div>
                        ))}
                    </Slider>{/*SS*/}
                    <VideoPlayer isPlaying={isPause} handleMonitor={videoPlayerHandleMonitor} handlePlay={videoPlayerHandlePlay} handlePause={videoPlayerHandlePause} handleBackward={videoPlayerHandleBackward} handleLeft={videoPlayerHandleLeft} handleRight={videoPlayerHandleRight}></VideoPlayer>
                    {/*isPause && <AiFillPauseCircle className="pausacircle"></AiFillPauseCircle>*/}
                </div>
            )}


        {viewer && <BannerViewerCard exitCallback={onExitCallback} title={title} ListImg={List}></BannerViewerCard>}
        </>
    );
};

export default BannerCardSlider;
