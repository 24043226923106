import Banner from "../../elements/common/banner";
import SectionVideo2 from "../../elements/common/section-video2";
import SectionWhyChooseUs4 from "../perche_sceglierci/section-why-choose-us4";
import Clienti_Component from "../clienti/clienti_component";
import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import ServiziCard from "./servizicard";
import VideoBanner from "../../elements/videoBanner";
import { useTranslation } from "react-i18next";

function ServiziHome() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    const {t} = useTranslation("servizi");

    return (
        <>
             <VideoBanner
                Title={t("intro.header")}
                Desc={t("intro.desc")}
                Button={t("intro.button")}
                imgSrc=""
                videoSrc="/assets/images/servizi/servizivideo.mp4"
                isVideo={true}
                href="#inizioref"
                ButtonThird={true}
                backgroundColor="rgba(2, 40, 255, 0.4)"
            />
            {/*<Banner _data={bannerData.services} />*/}
            <ServiziCard></ServiziCard>
            <SectionVideo2 />
            <SectionWhyChooseUs4 />

        </>
    )
}

export default ServiziHome;