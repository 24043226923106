import ItodoImage from "../../elements/itodo-img";
import { useTranslation } from 'react-i18next';
import Title from "../../elements/title";
import NewHeaderTitle from "../../elements/newHeaderTitle";
import HomeSection from "../../elements/homeSection";


const ImaginaIntro = () => {

    const { t } = useTranslation("imagina");


    const getIncrementalDescriptions = (variabile) => {
        let descriptions = [];
        let i = 1;
        let desc;

        while ((desc = t(`${variabile}${i}`)) && desc !== `${variabile}${i}`) {
            descriptions.push(desc);
            i++;
        }

        return descriptions;
    };

    const descriptionsheader = getIncrementalDescriptions("board.desc_header_");
    const descriptionslower = getIncrementalDescriptions("board.desc_lower_");

    return (
        <>
        <div id="inizioref"></div>
            <NewHeaderTitle
                title={t("board.title_header")}
                descList={descriptionsheader}
                buttonText={t("board.button_header")}
                imgSrc="assets/images/case-study/automazione/scheda.jpg"
                imgSrcRidimensionabile="assets/images/case-study/automazione/scheda.jpg"
                imgSrcRidimensionabileBackground="assets/images/case-study/automazione/scheda.jpg">
            </NewHeaderTitle>


            {/*<div className="section-full wow fadeInLeft" data-wow-duration="1500ms">
                <Title title={t("intro.header")}></Title>
    </div>*/}

            <HomeSection
                title={t("board.title_lower")}
                imgSrcList={t('board.dataImg', { returnObjects: true })}
                descriptionList={descriptionslower}
                putBackground={true}
                buttonText={t("board.button_lower")}
                buttonLink="/contact-us"
                imgLogo={t("board.logoImg")}
            >
            </HomeSection>

            <div className="servizio-card-upper section-full wow fadeInDown" data-wow-duration="1500ms">
                <div className="servizio-card-lista">
                    <div className="servizio-card-lista-lowerdiv-imagina">
                        <i className="flaticon-physics secondalista" />
                        <div className="servizio-card-lista-lowerdiv-title">{t("board.document_scanning")}</div>
                    </div>
                    <div className="servizio-card-lista-lowerdiv-imagina">
                        <i className="flaticon-physics secondalista" />
                        <div className="servizio-card-lista-lowerdiv-title">{t("board.cheque_scanning")}</div>
                    </div>
                    <div className="servizio-card-lista-lowerdiv-imagina">
                        <i className="flaticon-physics secondalista" />
                        <div className="servizio-card-lista-lowerdiv-title">{t("board.lottery_ticket_scanning")}</div>
                    </div>
                    <div className="servizio-card-lista-lowerdiv-imagina">
                        <i className="flaticon-physics secondalista" />
                        <div className="servizio-card-lista-lowerdiv-title">{t("board.banknote_scanning")}</div>
                    </div>
                    <div className="servizio-card-lista-lowerdiv-imagina">
                        <i className="flaticon-physics secondalista" />
                        <div className="servizio-card-lista-lowerdiv-title">{t("board.inkjet_printing")}</div>
                    </div>


                </div>
            </div>
        </>
    );
};

export default ImaginaIntro;