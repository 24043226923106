import CardViewer from "../../elements/cardviewer";
import Title from "../../elements/title";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import { MdAdfScanner } from "react-icons/md";
import { FaIndustry } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../supervisor/supervisor_tecnico/maintenance/AppContext";

const Intro_Prodotti = () => {

    const  {t} = useTranslation("home");
    const getIncrementalDescriptions = (variabile) => {
        let descriptions = [];
        let i = 1;
        let desc;
    
        while ((desc = t(`${variabile}${i}`)) && desc !== `${variabile}${i}`) {
            descriptions.push(desc);
            i++;
        }
    
        return descriptions;
    };

    const descriptions= getIncrementalDescriptions("home.intro.desc_");
    const cardData = t('home.intro.cardData', { returnObjects: true });

    if (Array.isArray(cardData) && cardData !== null) {
    } else {
        console.error('La lista non è disponibile o non è un array.');
    }

    const cardDataIcons = [
        {
            iconFa: <FaIndustry />,
        },
        {
            iconFa: <MdOutlineSupervisorAccount />,
        },
        {
            iconFa: <MdManageAccounts />,
        },
        {
            iconFa: <MdAdfScanner />,
        }
    ];

    const combinedCardData = cardData.map((card, index) => {
        return {
            ...card,
            iconFa: cardDataIcons[index]?.iconFa
        };
    });
    

    return (
        <>
            <div className="card-viewer-div">
                <Title
                    title={t("home.intro.header")}
                    descriptionList={descriptions}/>
                

                <CardViewer addClassCircle="isNotJobCircle" addClass="isNotJob" cards={combinedCardData} isFa={true}></CardViewer>
            </div>
        </>
    );
};

export default Intro_Prodotti;